import { Route, Routes } from 'react-router-dom'
import './theme/App.scss'
import { PublicRoutes } from './constants/routes'
import HomePage from './pages/HomePage/HomePage'

import Redirect from './components/Redirect/Redirect'
import Layout from './components/Layout/Layout'
import { FC } from 'react'
import ContractPage from './pages/ContractPage/ContractPage'

const App: FC = () => {
  return (
    <Routes>
      <Route path={PublicRoutes.ROOT} element={<Redirect url={PublicRoutes.HOME} />} />
      <Route path={PublicRoutes.ROOT} element={<Layout />}>
        <Route path={PublicRoutes.HOME} element={<HomePage />} />
        <Route path={PublicRoutes.CONTRACT} element={<ContractPage />} />
      </Route>
      <Route path='*' element={<Redirect url={PublicRoutes.HOME} />} />
    </Routes>
  )
}

export default App
