import React from 'react'
import { Outlet } from 'react-router-dom'
import Deco from '../../assets/img/deco-aufbau@2x.png'

const Layout: React.FC = () => {
  return (
    <>
      <main className='main mh-100vh'>
        <div className='position-absolute w-100 h-100 overflow-hidden'>
          <div className='deco-3' />
          <img src={Deco} alt='' className='deco-1' />
        </div>
        <div className='container-fluid position-relative z-10'>
          <div className='content '>
            <Outlet />
          </div>
        </div>
      </main>

      <div className='position-absolute overflow-hidden w-100 h-100 bottom-0' style={{ pointerEvents: 'none' }}>
        <div className='deco-4 d-none d-md-block' />
        <img src={Deco} alt='' className='deco-2' />
      </div>
    </>

  )
}

export default Layout
