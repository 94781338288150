
import { Box, Container, Grid, TextField } from '@mui/material'
import { FC } from 'react'
import { CurrencyFormat } from '../../../utils/Currency.utility'
import { getMonto } from '../../../utils/getMonto'
import { useHomePageContext } from '../context/HomePage.context'
import { styled } from '@mui/material/styles'
// import { useHomePageContext } from '../context/HomePage.context'

const CssTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: '#7167a8'
  },
  '& .MuiOutlinedInput-input': {
    color: '#fff'
  },
  '& label.Mui-focused': {
    color: '#7167a8'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#7167a8'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#7167a8'
    },
    '&:hover fieldset': {
      borderColor: '#7167a8'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7167a8'
    }
  }
})

const StepThree: FC = () => {
  const { ctx, saveCtx } = useHomePageContext()
  // const { setIsStepTwoValid, email, setEmail } = useHomePageContext()

  return (
    <div>
      <Box my={1}>
        <div className='container mb-3 mt-5'>
          <div className='bg-title-80 rounded-4' style={{ padding: '2px' }}>
            <div className='bg-body rounded-4 container text-white'>
              <div className='row py-4'>
                <div className='col-6 p-3'>
                  <h5 className='fw-bold h3'>{ctx.data.sbu.name}</h5>
                  {
                ctx.data.sbu.id_sbu !== 2 || ([1, 2].includes(ctx.data.brand.id_brand) && ctx.data.sbu.id_sbu === 2)
                  ? (<p className='mb-0'>{ctx.data.model.model} - {ctx.data.capacity.capacity}</p>)
                  : (<p className='mb-0'>{ctx.data.cpu.name} - {ctx.data.screen.screen} - {ctx.data.ram.ram} ram - {ctx.data.capacity.capacity} HD</p>)
              }
                </div>
                <div className='col-6 p-3'>
                  <p className='fw-bold text-end mb-0'>Monto de descuento</p>
                  <p className='h2 fw-bold text-end'>{CurrencyFormat(getMonto(ctx.data))}</p>

                </div>
              </div>
            </div>
          </div>

        </div>
        <Container maxWidth='sm'>
          <Grid container spacing={3} display='flex' alignItems='center' justifyContent='center'>
            <Grid item xs={12} sm={6}>
              <CssTextField
                label='Nombre'
                variant='outlined'
                className='border-primary'
                value={ctx.data.name}
                fullWidth
                type='text'
                error={ctx.validation && ctx.data.name.trim() === ''}
                helperText={ctx.validation && ctx.data.name.trim() === '' ? 'Debe ingresar su nombre' : ''}
                autoComplete='off'
                onBlur={() => saveCtx({ ...ctx, validation: true })}
                onChange={(e) => saveCtx({ ...ctx, data: { ...ctx.data, name: e.target.value } })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CssTextField
                label='Apellido'
                variant='outlined'
                value={ctx.data.lastname}
                fullWidth
                type='text'
                error={ctx.validation && ctx.data.lastname.trim() === ''}
                helperText={ctx.validation && ctx.data.lastname.trim() === '' ? 'Debe ingresar su apellido' : ''}
                autoComplete='off'
                onBlur={() => saveCtx({ ...ctx, validation: true })}
                onChange={(e) => saveCtx({ ...ctx, data: { ...ctx.data, lastname: e.target.value } })}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                label='Correo electronico'
                variant='outlined'
                value={ctx.data.email}
                fullWidth
                type='email'
                error={ctx.validation && ctx.data.email.trim() !== '' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ctx.data.email)}
                onBlur={() => saveCtx({ ...ctx, validation: ctx.data.email.trim() !== '' })}
                helperText={ctx.validation && ctx.data.email.trim() !== '' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ctx.data.email) ? 'Debe ingresar un correo válido' : ''}
                autoComplete='off'
                onChange={(e) => saveCtx({ ...ctx, data: { ...ctx.data, email: String(e.target.value) } })}
              />
            </Grid>
          </Grid>
        </Container>

      </Box>
    </div>
  )
}

export default StepThree
