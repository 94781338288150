import axios from 'axios'

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8', Accept: 'text/json' }
})

export const sourceAxios = axios.CancelToken.source()

export const webApiService = {
  getBySbu: async () => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getAllSbu`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getDeviceInfo: async () => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getDeviceInfo`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getBrandBySbu: async (idSbu: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getBrandBySbu/${idSbu}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getModelByBrand: async (idSbu: string, idBrand: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getModelByBrand/${idSbu}/${idBrand}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getCapacityByModel: async (idSbu: string, idBrand: string, idModel: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getCapacityByModel/${idSbu}/${idBrand}/${idModel}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getQuestions: async () => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getQuestions`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  saveData: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}save`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  createLoginCodeAufbau: async () => {
    const url = `${process.env.REACT_APP_CRUD_PHONECHECK}createLoginCodeAufbau`
    return await axiosInstance.request({ method: 'POST', url }).then((res: any) => res.data)
  },
  getAllCPU: async () => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getAllCpu`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getGenerationByCpu: async (idCpu: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getGenerationByCpu/${idCpu}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getScreenByCpu: async (idCpu: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getScreenByCpu/${idCpu}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getRamByCpu: async (idCpu: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getRamByCpu/${idCpu}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getCapacityByCpu: async (idCpu: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getCapacityByCpu/${idCpu}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  sendNotification: async (data: any) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_AUFBAU === undefined ? '' : process.env.REACT_APP_SERVICE_AUFBAU}sendNotification`,
      data
    }

    try {
      const response = await axios.request(config)
      return response.data
    } catch (error) {
      console.error('Error en la solicitud:', error)
      throw error
    }
  },
  getByEnvelopeId: async (id: string) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}getByEnvelopeId/${id}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  signContract: async (data: any) => {
    const url = `${process.env.REACT_APP_SERVICE_AUFBAU}signContract`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  }
}
