import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { HTMLCONTRATO } from '../../constants/html'
import { webApiService } from '../../services'
import { htmlReplaceContract } from '../../utils/htmlReplaceContract'
import ModalSign from './components/ModalSign'

const ContractPage = () => {
  const [data, setData] = useState<{ loading: boolean, tradein: any, openModal: boolean, firma: string }>({ loading: false, tradein: null, openModal: false, firma: '' })
  const { idContrato } = useParams<{ idContrato: string }>()

  const html = data.tradein !== null && data.tradein !== undefined
    ? htmlReplaceContract(HTMLCONTRATO, {
      id: data.tradein.id,
      name: String(data.tradein.name),
      lastname: String(data.tradein.lastname),
      rut: String(data.tradein.rut),
      city: data.tradein.city,
      address: String(data.tradein.address),
      state: String(data.tradein.state),
      sbu: String(data.tradein.sbu),
      brand: data.tradein.wasRecalculated === 1 ? String(data.tradein.make) : String(data.tradein.brand),
      model: data.tradein.wasRecalculated === 1 ? String(data.tradein.modelName) : String(data.tradein.model),
      imei: String(data.tradein.serie),
      discount_amount: data.tradein.new_discount_amount !== null ? String(data.tradein.new_discount_amount) : String(data.tradein.discount_amount)
    })
    : ''

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getByEnvelopeId(String(idContrato)).then(res => {
      setData({ ...data, loading: false, tradein: res.data.length === 0 ? undefined : res.data[0] })
      console.log('🚀 ~ webApiService.getByEnvelopeId ~ res.data[0]:', res.data)
    })
      .catch((error) => {
        setData({ ...data, loading: false })
        console.log('Error loading data:', error)
      })
  }

  const handleSignContract = () => {
    setData({ ...data, openModal: true })
  }

  const saveFirma = (firma: string) => {
    setData({ ...data, firma, openModal: false })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato])

  if (data.tradein === null || data.loading) {
    return (
      <div className='position-relative' style={{ zIndex: 1 }}>
        <div className='container mx-auto p-1 py-md-4'>
          <div className='row pb-2 position-relative' style={{ height: '70px' }}>
            <div className='col-6 h-100'><img src='https://apps-trade-in-dev.s3.us-west-2.amazonaws.com/assets/logo-aufbau.png' className='h-100 w-auto ms-auto' alt='' /></div>
            <div className='col-6 h-100 d-flex'><img src='https://apps-trade-in-dev.s3.us-west-2.amazonaws.com/assets/logo-reuse.png' className='h-100 w-auto ms-auto' alt='' /></div>
          </div>
          <div className='bg-white border-black border-1 p-5 mx-auto'>
            Cargando...
          </div>
        </div>
      </div>
    )
  }

  if (data.tradein.url_contract !== null && data.tradein.url_contract !== '') {
    return (
      <div className='position-relative' style={{ zIndex: 1 }}>
        <div className='container mx-auto p-1 py-md-4'>
          <div className='row pb-2 position-relative' style={{ height: '70px' }}>
            <div className='col-6 h-100'><img src='https://apps-trade-in-dev.s3.us-west-2.amazonaws.com/assets/logo-aufbau.png' className='h-100 w-auto ms-auto' alt='' /></div>
            <div className='col-6 h-100 d-flex'><img src='https://apps-trade-in-dev.s3.us-west-2.amazonaws.com/assets/logo-reuse.png' className='h-100 w-auto ms-auto' alt='' /></div>
          </div>
          <div className='bg-white border-black border-1 p-5 mx-auto text-center'>
            Este contrato ya fue firmado, para descargarlo haga click en el botón
            <div className='py-4'>
              <Button size='large' variant='contained' color='success' href={data.tradein.url_contract} target='_blank'>Descargar</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='position-relative' style={{ zIndex: 1 }}>
      <div className='container mx-auto p-1 py-md-4'>
        {
          data.tradein.status === 'VALIDADO' && data.firma === ''
            ? <Button size='large' variant='contained' color='success' onClick={handleSignContract} style={{ position: 'fixed', bottom: '40px', right: '40px' }}>Firmar</Button>
            : null
        }
        <div className='row pb-2 position-relative' style={{ height: '70px' }}>
          <div className='col-6 h-100'><img src='https://apps-trade-in-dev.s3.us-west-2.amazonaws.com/assets/logo-aufbau.png' className='h-100 w-auto ms-auto' alt='' /></div>
          <div className='col-6 h-100 d-flex'><img src='https://apps-trade-in-dev.s3.us-west-2.amazonaws.com/assets/logo-reuse.png' className='h-100 w-auto ms-auto' alt='' /></div>
        </div>
        <div className='bg-white border-black border-1 p-5 mx-auto'>
          <div dangerouslySetInnerHTML={{ __html: html.replace('{{firma}}', data.firma) }} />
        </div>
      </div>
      {data.openModal ? <ModalSign isOpen={data.openModal} saveFirma={saveFirma} onClose={() => setData({ ...data, openModal: false })} html={html} /> : null}
    </div>
  )
}

export default ContractPage
