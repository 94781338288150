export const CurrencyFormat = (monto : number) => {
  if (monto === 0) {
    return '$0'
  } else {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0
    }).format(monto)
  }
}
