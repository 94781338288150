import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import store from './store/store'
import { SnackbarProvider } from 'notistack'

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <SnackbarProvider maxSnack={5} autoHideDuration={4000}>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
)
