
import { Box, FormControl, FormControlLabel, Paper, Radio, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { webApiService } from '../../../services'
import { useHomePageContext } from '../context/HomePage.context'
// import { useHomePageContext } from '../context/HomePage.context'

const StepTwo: FC = () => {
  const { ctx, saveCtx } = useHomePageContext()
  const [data, setData] = useState<{ loading: boolean, questionList: any[] }>({ loading: false, questionList: [] })
  const { enqueueSnackbar } = useSnackbar()

  const loadData = async () => {
    webApiService.getQuestions().then(res => {
      if (res.success) {
        setData({ ...data, questionList: res.data, loading: false })
        saveCtx({ ...ctx, steps: { ...ctx.steps, isValid: (ctx.data.answers.length === res.data.filter((q : any) => q.id_parent_question === null).length) } })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleChangeQuestion = (event: ChangeEvent<HTMLInputElement>, idPregunta: number) => {
    console.log('🚀 ~ handleChangeQuestion ~ arrResp:', data.questionList, (event.target as HTMLInputElement).value)

    const arr = [...ctx.data.answers]
    console.log('🚀 ~ handleChangeQuestion ~ arr:', arr)

    if (arr.some(e => e.id_question === idPregunta)) {
      const idx = arr.findIndex(e => e.id_question === idPregunta)
      console.log('🚀 ~ handleChangeQuestion ~ idx:', idx)
      arr.splice(idx, 1)
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    } else {
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    }
    console.log('Entro', ctx.data.answers.length, data.questionList.filter(q => q.id_parent_question === null).length)
    saveCtx({ ...ctx, data: { ...ctx.data, answers: arr }, steps: { ...ctx.steps, isValid: arr.length === data.questionList.filter(q => q.id_parent_question === null).length } })
  }

  useEffect(() => {
    loadData()
    return () => setData({ loading: false, questionList: [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {
        data.questionList.filter(q => q.id_parent_question === null).map((p:any, i: number) => {
          return (
            <Box my={1} key={i}>
              <Paper variant='outlined' sx={{ padding: 2 }} className='bg-transparent text-white'>
                <FormControl className='w-100'>
                  <p className='border-bottom border-primary px-5 py-3'>{p.questions}</p>
                  <div className='border border-primary p-2 rounded-4'>
                    <RadioGroup
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name={'radio-group-' + (i)}
                      onChange={(e) => handleChangeQuestion(e, p.id)}
                      value={ctx.data.answers.find(a => a.id_question === p.id)?.id_answer}
                    >
                      {
                      data.questionList.filter((a: any) => Number(a.id_parent_question) === p.id).map((a:any, n: number) => {
                        return (<FormControlLabel key={n} value={a.id} control={<Radio className='text-white' />} label={a.questions} />)
                      })
                    }
                    </RadioGroup>
                  </div>

                </FormControl>
              </Paper>

            </Box>
          )
        })
      }
    </div>
  )
}

export default StepTwo
