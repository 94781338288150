import { Box, Card, CardContent, Container, Divider, Paper, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { webApiService } from '../../../services'
import { CurrencyFormat } from '../../../utils/Currency.utility'
import { useHomePageContext } from '../context/HomePage.context'

const colourStyles: any = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'transparent', border: '0' }),
  singleValue: (styles: any) => ({ ...styles, color: '#fff' }),
  menu: (styles: any) => ({ ...styles, backgroundColor: '#1E203F', color: '#fff' }),
  option: (styles: any, { data, isDisabled, isFocused } : any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#6F7E8C' : '#1E203F',
      color: '#fff',
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  }
}

const StepOne: React.FC = () => {
  const { ctx, saveCtx } = useHomePageContext()
  const [data, setData] = useState<{ loading: boolean, sbuList: any[], brandList: any[], modelList: any[], capacityList: any[], cpuList: any[], generationList: any[], screenList: any[], ramList: any[]}>({ loading: false, sbuList: [], brandList: [], modelList: [], capacityList: [], cpuList: [], generationList: [], screenList: [], ramList: [] })
  const { enqueueSnackbar } = useSnackbar()

  const loadData = async () => {
    if (ctx.data.sbu.id_sbu === 2) {
      const res : any = await Promise.all([
        webApiService.getBySbu(),
        webApiService.getBrandBySbu(String(ctx.data.sbu.id_sbu)),
        webApiService.getAllCPU(),
        webApiService.getGenerationByCpu(String(ctx.data.cpu.id)),
        webApiService.getScreenByCpu(String(ctx.data.cpu.id)),
        webApiService.getRamByCpu(String(ctx.data.cpu.id)),
        webApiService.getCapacityByCpu(String(ctx.data.cpu.id))
      ]).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
      })
      console.log('SBU =>>', res[0].data.filter((e:any) => e.id_sbu === 1 || e.id_sbu === 3))
      setData({
        ...data,
        sbuList: res[0].data.filter((e:any) => e.id_sbu === 1),
        brandList: res[1].data,
        cpuList: res[2].data,
        generationList: ctx.data.generation.id_cpu_generation !== 0 ? res[3].data.map((item: any) => { return { ...item, value: item.id_cpu_generation, label: item.cpu_generation } }) : [],
        screenList: ctx.data.screen.id_screen !== 0 ? res[4].data.map((item: any) => { return { ...item, value: item.id_screen, label: item.screen } }) : [],
        ramList: ctx.data.ram.id_ram !== 0 ? res[5].data.map((item: any) => { return { ...item, value: item.id_ram, label: item.ram } }) : [],
        capacityList: ctx.data.capacity.id_capacity !== 0 ? res[6].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + CurrencyFormat(item.amount) } }) : []
      })
    } else {
      const res : any = await Promise.all([
        webApiService.getBySbu(),
        webApiService.getBrandBySbu('1'),
        webApiService.getModelByBrand(String(ctx.data.sbu.id_sbu), String(ctx.data.brand.id_brand)),
        webApiService.getCapacityByModel(String(ctx.data.sbu.id_sbu), String(ctx.data.brand.id_brand), String(ctx.data.model.id_model))
      ]).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
      })

      setData({
        ...data,
        // sbuList: res[0].data.filter((e:any) => e.id_sbu === 1),
        sbuList: res[0].data.filter((e:any) => e.id_sbu === 1 || e.id_sbu === 3),
        brandList: ctx.data.sbu.id_sbu !== 0 ? res[1].data : [],
        modelList: ctx.data.model.id_model !== 0 ? res[2].data.map((item: any) => { return { ...item, value: item.id_model, label: item.model } }) : [],
        capacityList: ctx.data.capacity.id_capacity !== 0 ? res[3].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + CurrencyFormat(item.amount) } }) : []
      })
    }
  }
  console.log()

  const onClickSbu = (sbu: any) => {
    saveCtx({
      ...ctx,
      steps: { ...ctx.steps, isValid: false },
      data: {
        ...ctx.data,
        sbu,
        brand: { id_brand: 0, discount: '', brand: '', value: '', label: '' },
        model: { id_model: 0, model: '', value: '', label: '', amount: 0 },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 },
        cpu: { id: 0, name: '', value: '', label: '' },
        generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
        screen: { id_screen: 0, screen: '', value: '', label: '' },
        ram: { id_ram: 0, ram: '', value: '', label: '' }
      }
    })
    setData({ ...data, loading: true })
    webApiService.getBrandBySbu(String(sbu.id_sbu)).then(res => {
      if (res.success) {
        // setData({ ...data, brandList: res.data, loading: false })
        setData({ ...data, brandList: sbu.id_sbu === 3 ? res.data.filter((brand: any) => brand.id_brand === 1) : res.data, loading: false }) // Solo marca Apple para Tablets
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleBrandChange = (value: any) => {
    saveCtx({
      ...ctx,
      steps: { ...ctx.steps, isValid: false },
      data: {
        ...ctx.data,
        brand: value,
        model: { id_model: 0, model: '', value: '', label: '', amount: 0 },
        cpu: { id: 0, name: '', value: '', label: '' },
        generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
        screen: { id_screen: 0, screen: '', value: '', label: '' },
        ram: { id_ram: 0, ram: '', value: '', label: '' },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
      }
    })
    setData({ ...data, loading: true })
    if (ctx.data.sbu.id_sbu === 1 || ctx.data.sbu.id_sbu === 3 || ctx.data.sbu.id_sbu === 5 || (ctx.data.sbu.id_sbu === 2 && value.id_brand === 1) || (ctx.data.sbu.id_sbu === 2 && value.id_brand === 2)) {
      webApiService.getModelByBrand(String(ctx.data.sbu.id_sbu), String(value.id_brand)).then(res => {
        if (res.success) {
          setData({ ...data, modelList: res.data.map((item: any) => { return { ...item, value: item.id_model, label: (item.capacity > 0) ? item.model : item.model + ' - Descuento hasta ' + CurrencyFormat(item.amount) } }), loading: false })
        }
      }).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
    } else {
      webApiService.getAllCPU().then(res => {
        if (res.success) {
          setData({ ...data, cpuList: res.data.map((item: any) => { return { ...item, value: item.id, label: item.name } }), loading: false })
        }
      }).catch((error) => {
        console.log(error)
        enqueueSnackbar(error.message, { variant: 'error' })
        setData({ ...data, loading: false })
      })
    }
  }

  const handleModelChange = (value: any) => {
    saveCtx({ ...ctx, steps: { ...ctx.steps, isValid: false }, data: { ...ctx.data, model: value, capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 } } })
    setData({ ...data, loading: true })
    webApiService.getCapacityByModel(String(ctx.data.sbu.id_sbu), String(ctx.data.brand.id_brand), String(value.id_model)).then(res => {
      if (res.success) {
        setData({ ...data, capacityList: res.data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + CurrencyFormat(item.amount) } }), loading: false })
        if (res.data.length === 0) {
          saveCtx({ ...ctx, steps: { ...ctx.steps, isValid: true }, data: { ...ctx.data, model: value, capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 } } })
        }
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleProcesorChange = async (value: any) => {
    console.log(value)
    saveCtx({
      ...ctx,
      steps: { ...ctx.steps, isValid: false },
      data: {
        ...ctx.data,
        cpu: value,
        generation: { id_cpu_generation: 0, cpu_generation: '', value: '', label: '' },
        screen: { id_screen: 0, screen: '', value: '', label: '' },
        ram: { id_ram: 0, ram: '', value: '', label: '' },
        capacity: { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 }
      }
    })
    setData({ ...data, loading: true })

    const response = await Promise.all([
      webApiService.getGenerationByCpu(String(value.id)),
      webApiService.getScreenByCpu(String(value.id)),
      webApiService.getRamByCpu(String(value.id)),
      webApiService.getCapacityByCpu(String(value.id))
    ]).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
    console.log('🚀 ~ handleProcesorChange ~ response:', response)

    if (response !== undefined) {
      setData({
        ...data,
        generationList: response[0].data.map((item: any) => { return { ...item, value: item.id_cpu_generation, label: item.cpu_generation } }),
        screenList: response[1].data.map((item: any) => { return { ...item, value: item.id_screen, label: item.screen } }),
        ramList: response[2].data.map((item: any) => { return { ...item, value: item.id_ram, label: item.ram } }),
        capacityList: response[3].data.map((item: any) => { return { ...item, value: item.id_capacity, label: item.capacity + ' - Descuento hasta ' + CurrencyFormat(item.amount) } }),
        loading: false
      })
    }
  }

  useEffect(() => {
    loadData()
    return () => setData({ loading: false, sbuList: [], brandList: [], modelList: [], capacityList: [], cpuList: [], generationList: [], screenList: [], ramList: [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const CustomOption = (props: any) => {
    const { data } = props
    // eslint-disable-next-line no-debugger
    // debugger
    const [mainText, discountText] = data.label.split(' - Descuento hasta ')
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{mainText}</span>
          {discountText && (
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
              Descuento hasta {discountText}
            </span>
          )}
        </div>
      </components.Option>
    )
  }

  return (
    <>
      <Box my={4}>
        <div className='container-fluid'>
          <div className='row row-gap-4 d-flex justify-content-center'>
            {data.sbuList.map(item => (
              <div key={item.id_sbu} className='col-6 col-md-4'>
                <Card
                  className={ctx.data.sbu.id_sbu === item.id_sbu ? 'device-box p-0 border-1' : 'device-box p-0 border-0'}
                  onClick={() => onClickSbu({ id_sbu: item.id_sbu, name: item.id_sbu === 1 ? 'Smartphone' : item.id_sbu === 2 ? 'Notebook' : item.id_sbu === 3 ? 'Tablet' : item.id_sbu === 5 ? 'Smartwatch' : 'N/D' })}
                  sx={{
                    border: ctx.data.sbu.id_sbu === item.id_sbu ? '1px solid var(--primary-color)' : '1px solid #ddd',
                    cursor: 'pointer',
                    textAlign: 'center',
                    boxShadow: 'none'
                  }}
                >
                  <CardContent className='bg-body py-4'>
                    <p className='text-white fw-bold h4 pt-2 px-5 mb-0'>{item.id_sbu === 1 ? 'Smartphone' : item.id_sbu === 2 ? 'Notebook' : item.id_sbu === 3 ? 'Tablet' : item.id_sbu === 5 ? 'Smartwatch' : 'N/D'}</p>
                    <p className='text-white small fw-bold mb-1'>Descuento hasta</p>
                    <p className='fw-bold text-white h3'>{CurrencyFormat(item.max_amount)}</p>

                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Box>
      <Container maxWidth='sm'>
        <div hidden={ctx.data.sbu.id_sbu === 0}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona marca:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.brandList.map(item => { return { ...item, value: item.id_brand, label: item.brand + ' - Descuento hasta ' + CurrencyFormat(item.discount) } })}
              value={ctx.data.brand}
              onChange={(value) => handleBrandChange(value)}
              className='selectCustom'
              styles={colourStyles}
            />
          </Box>
        </div>
        <div hidden={ctx.data.brand.id_brand === 0 || (ctx.data.sbu.id_sbu === 2 && ![1, 2].includes(ctx.data.brand.id_brand))}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona modelo:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.modelList}
              value={ctx.data.model}
              onChange={(value) => handleModelChange(value)}
              className='selectCustom'
              styles={colourStyles}
            />
          </Box>
        </div>
        {/* Computadores */}
        <div hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || ctx.data.brand.id_brand === 0}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona procesador:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.cpuList}
              value={ctx.data.cpu}
              onChange={(value) => handleProcesorChange(value)}
              className='selectCustom'
              styles={colourStyles}
            />
          </Box>
        </div>
        <div hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || ctx.data.cpu.id === 0}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona generación:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.generationList}
              value={ctx.data.generation}
              onChange={(value: any) => saveCtx({ ...ctx, data: { ...ctx.data, generation: value } })}
              className='selectCustom'
              styles={colourStyles}
            />
          </Box>
        </div>
        <div hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || ctx.data.generation.id_cpu_generation === 0}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona pantalla:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.screenList}
              value={ctx.data.screen}
              onChange={(value: any) => saveCtx({ ...ctx, data: { ...ctx.data, screen: value } })}
              className='selectCustom'
              styles={colourStyles}
            />
          </Box>
        </div>
        <div hidden={ctx.data.sbu.id_sbu !== 2 || (ctx.data.sbu.id_sbu === 2 && [1, 2].includes(ctx.data.brand.id_brand)) || ctx.data.screen.id_screen === 0}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona memoria ram:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.ramList}
              value={ctx.data.ram}
              onChange={(value: any) => saveCtx({ ...ctx, data: { ...ctx.data, ram: value } })}
              placeholder='Seleccione memoria ram...'
            />
          </Box>
        </div>
        {/* Fin Computadores */}
        <div hidden={(ctx.data.model.id_model === 0 && ctx.data.ram.id_ram === 0) || data.capacityList.length === 0}>
          <Box my={1}>
            <Typography variant='body1' mb={1} mt={4} className='text-white'>Selecciona {ctx.data.sbu.id_sbu !== 2 ? 'memoria' : 'almacenamiento'}:</Typography>
            <Select
              isLoading={data.loading}
              isDisabled={data.loading}
              components={{ Option: CustomOption }}
              options={data.capacityList}
              value={ctx.data.capacity}
              onChange={(value) => saveCtx({ ...ctx, steps: { ...ctx.steps, isValid: true }, data: { ...ctx.data, capacity: value === null ? { id_capacity: 0, capacity: '', value: '', label: '', amount: 0 } : value } })}
              className='selectCustom'
              styles={colourStyles}
            />
          </Box>
        </div>
        <Box
          my={4} style={{
            display:
          (![1, 2].includes(ctx.data.brand.id_brand) && ctx.data.sbu.id_sbu === 2 && data.capacityList.length !== 0 && ctx.data.capacity.id_capacity !== 0 && !data.loading) ||
          (ctx.data.model.id_model !== 0 && data.capacityList.length === 0 && !data.loading) ||
          (ctx.data.model.id_model !== 0 && data.capacityList.length !== 0 && ctx.data.capacity.id_capacity !== 0 && !data.loading)
            ? 'block'
            : 'none'
          }}
        >
          <Paper variant='outlined' sx={{ padding: 2 }} style={{ background: '#1E203F', color: '#fff' }}>
            <Typography variant='h6' gutterBottom>Resumen de Selección:</Typography>
            <Divider />
            <Typography variant='body1' mt={2} gutterBottom>
              <strong>Dispositivo:</strong> {ctx.data.sbu.name}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>Marca:</strong> {ctx.data.brand.brand}
            </Typography>
            {
              ctx.data.sbu.id_sbu === 2 && ![1, 2].includes(ctx.data.brand.id_brand)
                ? (
                  <Typography variant='body1' gutterBottom>
                    <strong>Procesador:</strong> {ctx.data.cpu.name}
                  </Typography>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ![1, 2].includes(ctx.data.brand.id_brand)
                ? (
                  <Typography variant='body1' gutterBottom>
                    <strong>Generación:</strong> {ctx.data.generation.cpu_generation}
                  </Typography>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ![1, 2].includes(ctx.data.brand.id_brand)
                ? (
                  <Typography variant='body1' gutterBottom>
                    <strong>Pantalla:</strong> {ctx.data.screen.screen}
                  </Typography>
                  )
                : null
            }
            {
              ctx.data.sbu.id_sbu === 2 && ![1, 2].includes(ctx.data.brand.id_brand)
                ? (
                  <Typography variant='body1' gutterBottom>
                    <strong>Memoria ram:</strong> {ctx.data.ram.ram}
                  </Typography>
                  )
                : null
            }
            {
              ctx.data.model.id_model !== 0
                ? (
                  <Typography variant='body1' gutterBottom>
                    <strong>Modelo:</strong> {ctx.data.model.model}
                  </Typography>
                  )
                : null
            }
            {
              ctx.data.capacity.id_capacity !== 0
                ? (
                  <Typography variant='body1' gutterBottom>
                    <strong>{ctx.data.sbu.id_sbu !== 2 ? 'Memoria' : 'Almacenamiento'}:</strong> {ctx.data.capacity.capacity}
                  </Typography>
                  )
                : null
            }
          </Paper>
        </Box>
      </Container>
    </>
  )
}

export default StepOne
