import moment from 'moment'
import { CurrencyFormat } from './Currency.utility'

export const htmlReplaceContract: (html: string, data: any) => string = (html, data) => {
  const fecha = moment().locale('es').format('DD/MM/YYYY')
  const fullname = String(data.name) + ' ' + String(data.lastname)
  const identificacion: string = data.rut
  const marca: string = data.brand
  const modelo: string = data.model
  const imei: string = data.imei
  const discount = CurrencyFormat(Number(data.discount_amount))
  console.log('🚀 ~ discount:', data.discount_amount)

  html = html.replaceAll('{{fecha}}', fecha)
    .replaceAll('{{fullname}}', fullname)
    .replaceAll('{{identificacion}}', identificacion)
    .replaceAll('{{marca}}', marca)
    .replaceAll('{{modelo}}', modelo)
    .replaceAll('{{imei}}', imei)
    .replaceAll('{{discount}}', discount)

  return html
}
