
import { Document, Page, pdf } from '@react-pdf/renderer'
import { useSnackbar } from 'notistack'
import { FC, useRef, useState } from 'react'
import Html from 'react-pdf-html'
import { useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { blobToBase64 } from '../../../utils/blobToBase64.util'
import { webApiService } from '../../../services'
import { Box, Button, Modal, Typography } from '@mui/material'

interface IOwnProps {
    isOpen: boolean
    onClose: () => void
    html: string
    saveFirma: (firma: string) => void
}

const ModalSign: FC<IOwnProps> = ({ onClose, isOpen, html, saveFirma }) => {
  const [data, setData] = useState<{ loading: boolean }>({ loading: false })
  const signatureCanvasRef = useRef<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { idContrato } = useParams<{ idContrato: string }>()

  const clearSignature = async () => {
    if (signatureCanvasRef.current !== null) {
      signatureCanvasRef.current.clear()
    }
  }

  const signContract = async () => {
    if (signatureCanvasRef.current.isEmpty()) {
      enqueueSnackbar('Ingrese su firma', { variant: 'warning' })
      return
    }
    setData({ ...data, loading: true })
    html = html.replace('{{firma}}', '<img src="' + signatureCanvasRef.current.toDataURL('image/png') + '">')

    // convertir el html a pdf
    const pdfBlob = await pdf(<Document> <Page size='A4' style={{ fontFamily: 'Times-Roman', fontWeight: 'bold' }}> <Html style={{ margin: '40px' }} resetStyles={false}>{html}</Html> </Page></Document>).toBlob()
    console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
    const base64PDF = await blobToBase64(pdfBlob)
    console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)

    await webApiService.signContract({ signature: signatureCanvasRef.current.toDataURL('image/png'), pdf: base64PDF, envelopeId: idContrato }).then(res => {
      saveFirma('<img src="' + signatureCanvasRef.current.toDataURL('image/png') + '">')
      console.log('🚀 ~ awaitwebApiService.signContract ~ res:', res)
      // descargar pdf
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = res.data
      link.download = `${idContrato}.pdf`
      link.click()
      enqueueSnackbar('Contrato firmado.', { variant: 'success' })
      setData({ ...data, loading: false })
    }).catch(error => {
      console.log('🚀 ~ awaitwebApiService.signContract ~ error:', error)
      enqueueSnackbar('Error al firmar', { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          p: 2
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '800px',
            backgroundColor: 'white',
            borderRadius: 2,
            textAlign: 'center',
            maxHeight: '90vh',
            overflowY: 'auto'
          }}
        >
          <Typography
            id='modal-title'
            variant='h6'
            component='h2'
            sx={{
              backgroundColor: '#333',
              color: '#fff',
              width: '100%',
              padding: 2
            }}
          >
            Firmar contrato
          </Typography>
          <Box sx={{ mt: 2, mb: 2, p: 2 }}>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div>
                <p className='text-tiny'>(Firmar en el recuadro)</p>
                <div style={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', display: 'inline-block' }}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} />
                </div>
                <div>
                  <Button variant='contained' onClick={clearSignature} size='small' color='info' className='mt-1'>
                    Limpiar firma
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: '#f1f1f1',
              width: '100%',
              padding: 2,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              display: 'flex',
              justifyContent: 'center',
              gap: 2
            }}
          >
            <Button color='error' variant='contained' onClick={onClose} size='small'>
              Cancelar
            </Button>
            <Button
              color='primary'
              onClick={signContract}
              size='small'
              variant='contained'
              disabled={data.loading}
            >
              {data.loading ? 'Cargando...' : 'Firmar'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalSign
